import { render, staticRenderFns } from "./CommonTitle.vue?vue&type=template&id=bf8b12f8&scoped=true&"
import script from "./CommonTitle.vue?vue&type=script&lang=js&"
export * from "./CommonTitle.vue?vue&type=script&lang=js&"
import style0 from "./CommonTitle.vue?vue&type=style&index=0&id=bf8b12f8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf8b12f8",
  null
  
)

export default component.exports